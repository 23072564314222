import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import NavigationBar from '../components/NavigationBar';
import ContactForm from '../components/ContactForm';
import ContactInformation from '../components/ContactInformation';
import Footer from '../components/Footer';


import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const Contact = ({ isAuthenticated, setIsAuth }) => {
  const [mapInitialized, setMapInitialized] = useState(false);

  useEffect(() => {
    if (!mapInitialized) {
      
      const map = L.map('map').setView([46.8182, 8.2275], 8);

      
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);

      
      const customIcon = L.icon({
        iconUrl: 'marker.png', 
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
      });

      L.marker([46.8182, 8.2275], { icon: customIcon })
        .addTo(map)
        .bindPopup('We are here!'); 

      setMapInitialized(true);
    }
  }, [mapInitialized]);

  return (
    <div>
            <Header  isAuthenticated={isAuthenticated} setIsAuth={setIsAuth}  />

      <NavigationBar />
      <h1 className="display-4 text-center my-4">Contact Us</h1>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div id="map" style={{ height: '600px' }}></div>
          </div>
          <div className="col-md-6">
            <ContactInformation />
            <ContactForm />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;






