import React from 'react';

const Products = () => {
  
  const products = [
    { id: 1, name: '100 Pallet', price: 150, image: '/pallet.jpg' },
    { id: 2, name: '200 Pallet', price: 200, image: '/pallet.jpg' },
    { id: 3, name: '300 Pallet', price: 325, image: '/pallet.jpg' },
    { id: 4, name: '400 Pallet', price: 450, image: '/pallet.jpg' },
    { id: 5, name: '500 Pallet', price: 500, image: '/pallet.jpg' },
    { id: 6, name: '600 Pallet', price: 625, image: '/pallet.jpg' },
  ];

  return (
    <section className="products bg-light py-3">
      <div className="container">
        <h2 className="text-center">Open Bids on Market</h2>
        <div className="row">
          {products.map(product => (
            <div key={product.id} className="col-md-4">
              <div className="card mb-4">
                <img src={product.image} alt={product.name} className="card-img-top" />
                <div className="card-body">
                  <h3 className="card-title">{product.name}</h3>
                  <p className="card-text">${product.price}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Products;

