import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Header = ({ isAuthenticated, setIsAuth }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    console.log('Logging out...');
    localStorage.removeItem('token');
    console.log('After logout:', isAuthenticated);
    navigate("/login");
  };

  return (
    <header className="bg-dark text-light py-3">
      <div className="container">
        <div className="d-md-flex justify-content-between align-items-center">
          <Link to="/" className="logo-link">
            <img
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt="Logo"
              className="logo img-fluid"
              style={{ maxHeight: '50px' }}
            />
          </Link>
          <div className="login-register mt-3 mt-md-0">
            {isAuthenticated ? (
              <>
                <Link to="/profile" className="btn btn-light me-2">
                  Profile
                </Link>
                <button onClick={handleLogout} className="btn btn-outline-light">
                  Log out
                </button>
              </>
            ) : (
              <>
                <Link to="/login" className="btn btn-light me-2">
                  Login
                </Link>
                <Link to="/register" className="btn btn-outline-light">
                  Register
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
  
};

export default Header;






