import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ProfileInfo = ({ isAuthenticated, setIsAuth }) => {
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const token = localStorage.getItem('token');

    useEffect(() => {
        axios
            .get('https://dev-api.palletshunter.ch/api/v1/account/me', {
                headers: {
                    'accept': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            })
            .then((response) => {
                setUserData(response.data.payload);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setError('Error fetching user data. Please try again later.');
                setLoading(false);
            });
    }, [token]);

    return (
        <div className="row justify-content-center">
            <div className="col-md-8 mt-4">
                <h1 className="card-title text-center mb-4">User Profile</h1>
                {loading ? (
                    <p>Loading user data...</p>
                ) : error ? (
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                ) : (
                    <div>
                        <div className="form-group">
                            <label>Name:</label>
                            <p>{userData.name}</p>
                        </div>
                        <div className="form-group">
                            <label>Given Name:</label>
                            <p>{userData.given_name}</p>
                        </div>
                        <div className="form-group">
                            <label>Family Name:</label>
                            <p>{userData.family_name}</p>
                        </div>
                        <div className="form-group">
                            <label>Gender:</label>
                            <p>{userData.gender}</p>
                        </div>
                        <div className="form-group">
                            <label>Birthdate:</label>
                            <p>{userData.birthdate}</p>
                        </div>
                        <div className="form-group">
                            <label>Preferred Username:</label>
                            <p>{userData.preferred_username}</p>
                        </div>
                        <div className="form-group">
                            <label>Email:</label>
                            <p>{userData.email}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfileInfo;