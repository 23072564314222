import React from 'react';

const ContactInformation = () => {
  return (
    <div className="text-center mb-4">
      <h3>Contact Information</h3>
      <p>Email: info@palletshunter.com</p>
      <p>Phone: +1 123-123-1234</p>
      <p>Address:Lorem ipsum dolor sit amet, consectetur adipiscing elit. In erat quam, maximus ac neque ac, porta porttitor enim. Sed mauris sapien, scelerisque sed nulla vitae, dapibus tincidunt nisi.</p>
      
    </div>
  );
};

export default ContactInformation;
