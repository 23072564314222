import React from 'react';
import { FaAlignCenter } from 'react-icons/fa';

const CategoryList = () => {
  const categories = [
    {
      name: 'Europalette fabrikneu',
      description:
        'Die neue Europalette ist fabrikneu (direkt ab Werk) und wird üblicherweise in der Lebensmittelindustrie sowie bei Arzneimitteln und sonstigen gegen Verschmutzung empfindliche Waren eingesetzt',
      imageUrl: './1.png',
    },
    {
      name: 'Europalette gebraucht A-Klasse',
      description: (
        <div>
          <p>
            Die gebrauchte Europalette ist eine kostengünstige Alternative zu einer brandneuen Palette.
            Diese A-Klasse Europaletten wurden nur ein oder zwei Mal verwendet und sind daher preislich attraktiver als neue Modelle. Sie sehen fast aus wie neu und sind in einem gepflegten Zustand.
          </p>
        </div>
      ),
      imageUrl: './2.png',
    },
    
    {
      name: 'EPAL CP3 Paletten fabrikneu',
      description: (
        <div>
          <p>
            Die EPAL Palette ist eine wichtige Grundlage für den weltweiten Transport und die Lagerung von Waren.
            Dank ihrer hohen Qualitätsstandards stellen sie ein Höchstmass an Arbeitssicherheit sicher.
          </p>
          <p>
            Sie gewährleisten einen reibungslosen Transfer über Grenzen hinweg und bieten eine stabile Unterstützung für Güter aller Art.
            Mit der EPAL Palette können Logistikunternehmen auf einwandfreien Service vertrauen.
          </p>
        </div>
      ),
      imageUrl: './3.jpg',
    },
    

    {
      name: 'EPAL CP1 Paletten fabrikneu',
      description: (
        <div>
          <p>
            Die EPAL Palette ist eine wichtige Grundlage für den weltweiten Transport und die Lagerung von Waren.
            Dank ihrer hohen Qualitätsstandards stellen sie ein Höchstmass an Arbeitssicherheit sicher.
          </p>
          <p>
            Sie gewährleisten einen reibungslosen Transfer über Grenzen hinweg und bieten eine stabile Unterstützung für Güter aller Art.
            Mit der EPAL Palette können Logistikunternehmen auf einwandfreien Service vertrauen.
          </p>
        </div>
      ),
      imageUrl: './4.png',
    },
    

    {
      name: 'EPAL CP2 Paletten fabrikneu',
      description: (
        <div>
          <p>
            Die EPAL Palette ist eine wichtige Grundlage für den weltweiten Transport und die Lagerung von Waren.
            Dank ihrer hohen Qualitätsstandards stellen sie ein Höchstmass an Arbeitssicherheit sicher.
          </p>
          <p>
            Sie gewährleisten einen reibungslosen Transfer über Grenzen hinweg und bieten eine stabile Unterstützung für Güter aller Art.
            Mit der EPAL Palette können Logistikunternehmen auf einwandfreien Service vertrauen.
          </p>
        </div>
      ),
      imageUrl: './4.png',
    },
    

    {
      name: 'SBB / OBB Aufsatzrahmen fabrikneu',
      description: (
        <div>
          <p>
            Der Tauschrahmen SBB ist in der Schweiz das unverzichtbare System, um Waren sicher und effizient zu transportieren.
            Die genormten Tauschrahmen/Aufsetzrahmen passen auf die EPAL Palette 1200 x 800 und sind mit oder ohne Boden und Deckel erhältlich.
          </p>
          <ul style={{ textAlign: 'left' }}>
            <li>Tauschrahmen SBB sind schweizweit ein wichtiger Wert in der Logistik</li>
            <li>Tauschrahmen SBB sind in der Höhe je nach Bedürfnis erweiterbar</li>
            <li>Tauschrahmen SBB sichern die Lagerung von Waren</li>
            <li>Tauschrahmen SBB sorgen für eine maximale Arbeitssicherheit</li>
          </ul>
        </div>
      ),
      imageUrl: './6.png',
    },


    {
      name: 'SBB / OBB Aufsatzrahmen gebraucht',
      description: (<div>
        <p>
          Der gebrauchte SBB-Rahmen oder Aufsatzrahmen ist eine kostengünstige Alternative zur Neuware. Er eignet sich hervorragend für den Einsatz in der Logistik und in Hochbeeten.
        </p>
        <p>
          Die Tauschrahmen SBB sind in der Schweiz unerlässlich, um Waren effizient und sicher zu transportieren. Sie passen auf die EPAL Palette 1200 x 800 und sind mit oder ohne Boden und Deckel erhältlich. Darüber hinaus können sie je nach Bedarf in der Höhe erweitert werden. Durch den Einsatz von Tauschrahmen SBB wird sowohl die Lagerung von Waren gesichert als auch maximale Arbeitssicherheit gewährleistet.
        </p>
      </div>

      ),
      imageUrl: './7.png',
    },

    {
      name: 'SBB / OBB Tauschdeckel gebraucht',
      description: (<div>
        <p>
          SBB / OBB Tauschdeckel gebraucht
          Der gebrauchte SBB Tauschdeckel ist eine kostengünstige Alternative zu einem neuen Modell. Er gehört zum System der Tauschrahmen SBB und dient zur sicheren Abdeckung von Ladeeinheiten mit den Nennabmessungen 1200 mm x 800mm.
        </p>
        < p > Dieser Tauschdeckel sorgt für maximale Arbeitssicherheit, indem er die Ladung vor Druck von oben schützt.Er ist stabil und daher ein wichtiger Bestandteil des Systems der Tauschrahmen SBB in ganz der Schweiz.</p >
      </div>),
      imageUrl: './8.png',
    },

    {
      name: 'Europalette gebraucht B-C Klasse',
      description: (
        <div>
          <p>
            Die gebrauchte Europalette (B-Klasse) ist eine ideale Wahl, wenn Sie nicht unbedingt das Neueste haben müssen.
          </p>
          <p>
            Diese Palette erfüllt alle Anforderungen, die auch neue Paletten erfüllen müssen, und sie ist für den weiteren Einsatz im Lager unbedenklich. Aufgrund ihrer Zuverlässigkeit ist unsere gebrauchte Europalette das meistgefragteste und meistgesuchteste Produkt.
          </p>
        </div>
      ),
      imageUrl: './9.png',
    },

    {
      name: 'Faltrahmen gebraucht',
      description: (
        <div>
          <p>
            Die Faltrahmen sind ein beliebtes Produkt für Gartendekorationen in Form von Beeten, kleinen Sandkästen, Planschbecken für Kinder und Tiere oder sonstige dekorative Ideen.

          </p>
          <p>
            Wie die Beschreibung es bereits erwähnt, lassen sich die Rahmen bequem falten und sind daher schnell und einfach verstaut und ebenso wieder schnell einsatzbereit.

          </p>
        </div>
      ),
      imageUrl: './10.png',
    },

    {
      name: 'SBB / OBB Tauschdeckel fabrikneu',
      description: (
        <div>
          <p>
            Der Tauschdeckel SBB gehört zum System der Tauschrahmen SBB, um Waren sicher und ohne Schäden zu transportieren.
            Der Tauschdeckel SBB dient zur Abdeckung von Tauschrahmen SBB sowie von Ladeeinheiten mit den Nennabmessungen 1200 mm x 800 mm.
          </p>
          <ul style={{ textAlign: 'left' }}> 
            <li>Tauschdeckel SBB sind schweizweit ein wichtiger Teil, um Tauschrahmen SBB zu ergänzen</li>
            <li>Tauschdeckel SBB sichern die Ladung gegen Druck von oben</li>
            <li>Tauschdeckel SBB sind stabil und sorgen für maximale Arbeitssicherheit</li>
          </ul>
        </div>
      ),
      imageUrl: './11.png',
    }



  ];

  return (
    <div className="container">
      <div className="row">
        {categories.map((category, index) => (
          <div key={index} className="col-md-12">
            <div className="text-center mb-4">
              <img
                src={category.imageUrl}
                alt={category.name}
                style={{ width: '300px', height: '200px' }}
              />
            </div>
            <div className="text-center">
              <h3>{category.name}</h3>
              <p>{category.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryList;


