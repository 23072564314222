import React from 'react';
import NavigationBar from '../components/NavigationBar';
import AboutContent from '../components/AboutContent'; 
import Footer from '../components/Footer';
import Header from '../components/Header';

const About = ({ isAuthenticated, setIsAuth }) => {
  return (
    <div>
            <Header  isAuthenticated={isAuthenticated} setIsAuth={setIsAuth}  />

      <NavigationBar />
      <h1 className="display-4 text-center my-4">About Us</h1>
      <AboutContent /> 
      <Footer />
    </div>
  );
};

export default About;
