import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-dark text-white text-center py-3">
      <div className="container">
        <div className="row align-items-center">
          
          <div className="col-12 col-md-4 mb-3 mb-md-0 d-flex justify-content-center justify-content-md-start">
            <img src='logo.png' alt="Logo" style={{ maxHeight: '50px' }} />
          </div>
 
          <div className="col-12 col-md-4">
            <p>&copy; {new Date().getFullYear()} Palletshunter. All rights reserved.</p>
          </div>

          <div className="col-12 col-md-4 d-flex justify-content-center">
            <ul className="list-inline social-icons">
              <li className="list-inline-item">
                <a href="https://www.facebook.com/your-facebook-page" style={{ color: '#fff' }} target="_blank" rel="noopener noreferrer">
                  <FaFacebook />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.twitter.com/your-twitter-page" style={{ color: '#fff' }} target="_blank" rel="noopener noreferrer">
                  <FaTwitter />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.instagram.com/your-instagram-page" style={{ color: '#fff' }} target="_blank" rel="noopener noreferrer">
                  <FaInstagram />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;


