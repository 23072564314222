import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import Header from '../components/Header';

const Login = ({ isAuthenticated = false, setIsAuth }) => {
  const navigate = useNavigate();

  const initialFormData = {
    username: '',
    password: '',
  };
  const [formData, setFormData] = useState(initialFormData);
  const [loginSuccessMessage, setLoginSuccessMessage] = useState('');
  const [loginWarning, setLoginWarning] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://dev-api.palletshunter.ch/api/v1/account/login', formData, {
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json-patch+json',
        },
      });

      if (response.status === 200) {
        setLoginSuccessMessage('Login successful!');
        console.log('Logging in...');
        localStorage.setItem('token', response.data.payload.accessToken)
        isAuthenticated = true;
        setTimeout(() => {
          console.log('After login:', isAuthenticated);
          navigate('/profile',{isAuthenticated: isAuthenticated});
          
        }, 1000); 


      } else {
        setLoginWarning('Login failed. Please check your credentials.');
        navigate('/login')
      }
    } catch (error) {
      console.error('Login error:', error);
      setLoginWarning('Login failed. Please verify your account.');
    }
  };

  

  return (
    <div>
            <Header  isAuthenticated={isAuthenticated} setIsAuth={setIsAuth}  />

      <NavigationBar />
      <div className="container">
        <div className="row justify-content-center min-vh-100">
          <div className="col-md-6 mt-4">
            <div className="card">
              <div className="card-body">
                <h1 className="card-title text-center mb-4">Login</h1>
                <form onSubmit={handleLogin}>
                  <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input
                      type="username"
                      className="form-control"
                      id="username"
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group form-check">
                    <input type="checkbox" className="form-check-input" id="rememberMe" />
                    <label className="form-check-label" htmlFor="rememberMe">
                      Remember me
                    </label>
                  </div>
                  <button type="submit" className="btn btn-primary btn-block">
                    Login
                  </button>
                  <div style={{ paddingTop: '10px' }}>
                  {loginSuccessMessage && (
                    <div className="alert alert-success mb-3" role="alert" >
                      Login successful!
                    </div>
                  )}
                  {loginWarning && (
                    <div className="alert alert-warning mb-3" role="alert" >
                      {loginWarning}
                    </div>
                  )}
                    </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;



