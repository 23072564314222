import React from 'react';
import Header from '../components/Header';
import NavigationBar from '../components/NavigationBar';
import CategoryList from '../components/CategoryList'; 
import Footer from '../components/Footer';

const Categories = ({ isAuthenticated, setIsAuth }) => {
  return (
    <div>
            <Header  isAuthenticated={isAuthenticated} setIsAuth={setIsAuth}  />

      <NavigationBar />
      <h1 className="display-4 text-center my-4">Categories</h1>
      <CategoryList /> 
      <Footer />
    </div>
  );
};

export default Categories;

