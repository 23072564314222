import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const EmailVerification = () => {
  const [verificationStatus, setVerificationStatus] = useState('');
  const { verificationToken } = useParams();

  
  const successClass = "text-center text-success";
  const errorClass = "text-center text-danger";

  useEffect(() => {
    if (verificationToken) {
      axios
        .get(`https://dev-api.palletshunter.ch/api/v1/account/confirm?emailVerificationToken=${verificationToken}`)
        .then((response) => {
          if (response.status === 200) {
            setVerificationStatus('Thank you for verifying your account. Please continue to Login!');
          } else {
            setVerificationStatus('Verification failed. Please try again later.');
          }
        })
        .catch((error) => {
          setVerificationStatus('Verification failed. Please try again later.');
          console.error('Verification error:', error);
        });
    } else {
      setVerificationStatus('Invalid verification token.');
    }
  }, [verificationToken]);

  return (
    <div className="container py-3">
      <div className="row justify-content-center">
        <div className="col-md-8 mt-4">
          <div className="card bg-light py-3">
            <div className="card-body">
              <h1 className="card-title text-center mb-4">Email Verification</h1>
              {/* Use conditional rendering to apply the appropriate class */}
              <p className={verificationStatus.includes("successful") ? successClass : errorClass}>
                {verificationStatus}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;


