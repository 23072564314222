import React from 'react';

const AboutContent = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="card p-4 my-4 shadow">
            <h2 className="text-center mb-4">Lorem ipsum dolor sit amet!</h2>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In erat quam, maximus ac neque ac, porta porttitor enim. Sed mauris sapien, scelerisque sed nulla vitae, dapibus tincidunt nisi. Aliquam facilisis finibus odio, et porta velit hendrerit vel. Vivamus massa est, commodo eget ante at, maximus faucibus neque.
            </p>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In erat quam, maximus ac neque ac, porta porttitor enim. Sed mauris sapien, scelerisque sed nulla vitae, dapibus tincidunt nisi. Aliquam facilisis finibus odio, et porta velit hendrerit vel. Vivamus massa est, commodo eget ante at, maximus faucibus neque. Cras feugiat placerat nulla, a fringilla sapien. Quisque ac dolor a lorem posuere sollicitudin in scelerisque lectus. Quisque vel facilisis justo, vitae elementum lectus. In magna metus, rhoncus ac pulvinar eu, luctus et mauris. 
            </p>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutContent;

