import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { Nav } from 'react-bootstrap';
import ProfileInfo from '../UserComponents/ProfileInfo';
import Notifications from '../UserComponents/Notifications';
import Inbox from '../UserComponents/Inbox';
import Addresses from '../UserComponents/Addresses';
import PaymentInfo from '../UserComponents/PaymentInfo';
import ResetPassword from '../UserComponents/ResetPassword';

const menuItems = [
  { key: 'profile', title: 'Profile Information', component: ProfileInfo },
  { key: 'notifications', title: 'Notifications', component: Notifications },
  { key: 'inbox', title: 'Inbox', component: Inbox },
  { key: 'addresses', title: 'Addresses', component: Addresses },
  { key: 'payment', title: 'Payment Information', component: PaymentInfo },
  { key: 'password', title: 'Reset Password', component: ResetPassword },
];

const UserProfile = () => {
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedMenuItem, setSelectedMenuItem] = useState('profile');
  const [isAuthenticated,setIsAuth] = React.useState(false)
  const token = localStorage.getItem('token');
  const checkUserAuthentication = async () => {
    try {
      // sending req to check auth
      const response = await axios.get('https://dev-api.palletshunter.ch/api/v1/account/me', { 
        headers:{
          'Authorization': 'Bearer '+token,
          'accept': 'application/json',
        }
      });
  console.log(response.data);
      //burayı success yapınca sayfayı refreshleyince header degisiyor bitek
      setIsAuth(true);
    } catch (error) {
      console.error('Error checking user authentication:', error);
    }
    
    return false; 
    
  };

  React.useEffect(()=>{
    checkUserAuthentication()
  },[])
  useEffect(() => {
    axios
      .get('https://dev-api.palletshunter.ch/api/v1/account/me', {
        headers: {
          accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        setUserData(response.data.payload);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setError('Error fetching user data. Please try again later.');
        setLoading(false);
      });
  }, [token]);

  const handleMenuItemClick = (eventKey) => {
    setSelectedMenuItem(eventKey);
  };

  const SelectedComponent = menuItems.find((item) => item.key === selectedMenuItem)?.component;

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header isAuthenticated={isAuthenticated} setIsAuth={setIsAuth} />
      <NavigationBar />
      <div className="container py-3 flex-grow-1">
        <div className="row">
          <div className="col-md-3">
            <Nav variant="pills" className="flex-column menu-tabs">
              {menuItems.map((menuItem) => (
                <Nav.Item key={menuItem.key}>
                  <Nav.Link
                    eventKey={menuItem.key}
                    onClick={() => handleMenuItemClick(menuItem.key)}
                    className={selectedMenuItem === menuItem.key ? 'active' : ''}
                  >
                    {menuItem.title}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>
          <div className="col-md-9">
            <div className="card bg-light py-3 content-card">
              <div className="card-body">
                {loading ? (
                  <p>Loading user data...</p>
                ) : error ? (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                ) : (
                  <div>
                    {SelectedComponent && <SelectedComponent userData={userData} />}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UserProfile;

