import React from 'react';
import MyCarousel from '../components/Carousel';
import Header from '../components/Header';
import NavigationBar from '../components/NavigationBar';
import Products from '../components/Products';
import CategoryList from '../components/CategoryList';
import Footer from '../components/Footer';
import axios from 'axios';

const Home = () => {
  const [isAuthenticated,setIsAuth] = React.useState(false)
  const token = localStorage.getItem('token');
  const checkUserAuthentication = async () => {
    try {
      // sending req to check auth
      const response = await axios.get('https://dev-api.palletshunter.ch/api/v1/account/me', { 
        headers:{
          'Authorization': 'Bearer '+token,
          'accept': 'application/json',
        }
      });
  console.log(response.data);
      //burayı success yapınca sayfayı refreshleyince header degisiyor bitek
      setIsAuth(true);
    } catch (error) {
      console.error('Error checking user authentication:', error);
    }
    
    return false; 
    
  };

  React.useEffect(()=>{
    checkUserAuthentication()
  },[])
  return (
    <div>
            <Header  isAuthenticated={isAuthenticated} setIsAuth={setIsAuth}  />

      <NavigationBar />
      <MyCarousel/>
      <Products />
      <CategoryList/>
      <Footer />
    </div>
  );
};

export default Home;