import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Register from './Pages/RegisterPage';
import Login from './Pages/LoginPage';
import Home from './Pages/HomePage';
import Marketplace from './Pages/MarketplacePage';
import Categories from './Pages/CategoriesPage';
import Contact from './Pages/ContactPage';
import About from './Pages/AboutPage';
import ProfilePage from './Pages/ProfilePage';
import EmailVerification from './Pages/EmailVerification';
import axios from 'axios';


const USER_ROLES = {
  VISITOR: 'visitor',
  MEMBER: 'member',
  ADMIN: 'admin',
};

const App = () => {
  const [userRole, setUserRole] = useState(USER_ROLES.VISITOR);
  const token = localStorage.getItem('token');
  const [isAuthenticated,setIsAuth] = useState(false)
  useEffect(() => {

    const checkUserAuthentication = async () => {
      try {
        // sending req to check auth
        const response = await axios.get('https://dev-api.palletshunter.ch/api/v1/account/me', { 
          headers:{
            'Authorization': 'Bearer '+token,
            'accept': 'application/json',
          }
        });
    console.log(response.data);
        //burayı success yapınca sayfayı refreshleyince header degisiyor bitek
        setIsAuth(true);
      } catch (error) {
        console.error('Error checking user authentication:', error);
      }
      
      return false; 
      
    };
    
    const fetchUserRole = async () => {
      try {
        // Send req & fetch the user role
        const response = await axios.get('', {
          withCredentials: true, 
        });
    
        return response.data.role; 
      } catch (error) {
        console.error('Error fetching user role:', error);
      }
    
      return USER_ROLES.VISITOR;
    };
      
        const simulateUserAuthentication = async () => {
          try {
            
            const isAuthenticated = await checkUserAuthentication(); 
    
            if (isAuthenticated) {
              
              const userRoleFromServer = await fetchUserRole(); 
              
              setUserRole(userRoleFromServer);
            } else {
        
              setUserRole(USER_ROLES.VISITOR);
            }
          } catch (error) {
            console.error('Error during authentication:', error);
          }
        };
    
        simulateUserAuthentication();
      }, [token]);

  return (
    <>
      <Routes>
      <Route path="/" element={<Home isAuthenticated={isAuthenticated} setIsAuth={setIsAuth} />} />

        <Route path="/register" element={userRole === 'visitor' || !isAuthenticated ? (<Register  isAuthenticated={isAuthenticated} setIsAuth={setIsAuth}/>) : (
              <Navigate to="/" />
            )} />
        <Route path="/login" element={userRole === 'visitor' || !isAuthenticated ? (<Login isAuthenticated={isAuthenticated} setIsAuth={setIsAuth}/>) : (
              <Navigate to="/" />
            )} />
        <Route path="/marketplace" element={<Marketplace isAuthenticated={isAuthenticated} setIsAuth={setIsAuth}/>} />
        <Route path="/categories" element={<Categories isAuthenticated={isAuthenticated} setIsAuth={setIsAuth}/>} />
        <Route path="/contact" element={<Contact isAuthenticated={isAuthenticated} setIsAuth={setIsAuth}/>} />
        <Route path="/about" element={<About isAuthenticated={isAuthenticated} setIsAuth={setIsAuth}/>} />
        <Route path="/account/verify/:verificationToken" element={<EmailVerification />} />

        <Route
          path="/profile" element={ <ProfilePage />}/>
        
      </Routes>
    </>
  );
};

export default App;








