import React from 'react';
import { Carousel } from 'react-bootstrap';

const MyCarousel = () => {
  const carouselItems = [
    {
      id: 1,
      description: 'Building Bridges, Where Buyers and Sellers Unite Online!',
      video: 'pl.mov',
    }
  ];

  return (
    <div style={{ overflow: 'hidden' }}>
      <Carousel>
        {carouselItems.map((item) => (
          <Carousel.Item key={item.id}>
            <video
              className="d-block w-100"
              src={item.video}
              alt={item.title}
              autoPlay
              muted
              loop
              style={{ maxHeight: '400px', objectFit: 'cover' }}
            />
            <Carousel.Caption
              className="carousel-caption"
            >

              <p style={{ color: '#fff' }}>{item.description}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
      <style>
        {`
          .carousel-control-prev,
          .carousel-control-next {
            display: none;
          }
          .carousel-indicators {
            display: none;
          }

          
          @media (max-width: 768px) {
            .carousel-caption {
              text-align: center;
              font-size: 14px; 
            }
          }
        `}
      </style>
    </div>
  );
};

export default MyCarousel;


