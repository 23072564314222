import React from 'react';
import Header from '../components/Header';
import NavigationBar from '../components/NavigationBar';
import Products from '../components/Products';
import Footer from '../components/Footer';

const Marketplace = ({ isAuthenticated, setIsAuth }) => {
  return (
    <div className="bg-light">
           <Header isAuthenticated={isAuthenticated} setIsAuth={setIsAuth}  />

      <NavigationBar />
      <h1 className="display-4 text-center my-4">Discover the Marketplace!</h1>
      <Products />
      <Footer />
    </div>
  );
};

export default Marketplace;
