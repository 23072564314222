import React, { useState } from 'react';
import axios from 'axios';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import Header from '../components/Header';


const Register = ({ isAuthenticated, setIsAuth }) => {
  const initialFormData = {
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    gender: '',
    birthdate: '2023-09-19',
  };
  const [formData, setFormData] = useState(initialFormData);
  const [registrationSuccessMessage, setRegistrationSuccessMessage] = useState('');
  const [registrationError, setRegistrationError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://dev-api.palletshunter.ch/api/v1/account/register', formData, {
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json-patch+json',
        },
      });

      if (response.status === 200) {
        setRegistrationSuccessMessage('A verification email has been sent to your email. Please check your inbox.');
        
        setFormData(initialFormData);
      } else {
        setRegistrationError('Registration failed. Please check your information.');
      }
    } catch (error) {
      console.error('Registration error:', error);
      setRegistrationError('Registration failed. Please try again later.');
    }
  };
  

  return (
    <div className="d-flex flex-column min-vh-100">
            <Header isAuthenticated={isAuthenticated} setIsAuth={setIsAuth}  />

      <NavigationBar />
      <div className="container py-3 flex-grow-1">
        <div className="row justify-content-center">
          <div className="col-md-8 mt-4">
            <div className="card bg-light py-3">
              <div className="card-body">
              {registrationSuccessMessage ? (
                  <div>
                    <h1 className="card-title text-center mb-4">Registration Successful!</h1>
                    <div className="alert alert-success mb-3" role="alert">
                      {registrationSuccessMessage}
                    </div>
                  </div>
                ) : (
                  <div>
                <h1 className="card-title text-center mb-4">Registration</h1>
                <form onSubmit={handleRegister}>
                  <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="gender">Gender</label>
                    <select
                      className="form-control"
                      id="gender"
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="birthdate">Birthdate</label>
                    <input
                      type="date"
                      className="form-control"
                      id="birthdate"
                      name="birthdate"
                      value={formData.birthdate}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group form-check py-3">
  <input
    type="checkbox"
    className="form-check-input"
    id="agreeToTerms"
    name="agreeToTerms"
    checked={formData.agreeToTerms}
    onChange={handleChange}
    required
  />
  <label className="form-check-label" htmlFor="agreeToTerms">
    I agree to the Terms and Conditions
  </label>
</div>
                  <button type="submit" className="btn btn-primary btn-block">
                    Register
                  </button>
                </form>
                <div style={{ paddingTop: '10px' }}>
                  {registrationSuccessMessage && (
                    <div className="alert alert-success mb-3" role="alert">
                      {registrationSuccessMessage}
                    </div>
                  )}
                  {registrationError && (
                    <div className="alert alert-danger mb-3" role="alert">
                      {registrationError}
                    </div>
                  )}
                  </div>
                  </div>
                )}
                </div>
              </div>
            </div>
          </div>
        </div>
      
      <Footer />
    </div>
  );
};

export default Register;






  